
.filled {
  height: 100%;

  .shine-spawner-wrapper {
    position: absolute;
    transform: scale(0.9);
  }
}

.shine-spawner {
  position: absolute;
  transition: opacity 1s linear;
  opacity: 1;
  $small: 10%;
  $medium: 15%;
  $big: 20%;

  &.small {
    width: $small;
    height: $small;
  }

  &.medium {
    width: $medium;
    height: $medium;
  }

  &.big {
    width: $big;
    height: $big;
  }
}
