@import "cardinal_slide";

.scale-fade-enter-active,
.scale-fade-leave-active {
  transition: all 1s ease;
}

.scale-fade-leave-from {
  transform: scale(1);
}


.scale-fade-leave-to {
  transform: scale(1.5);
}

.scale-fade-enter,
.scale-fade-leave-active {
  opacity: 0;
}

//------

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

// --- fade group
.fade-group-move, /* apply transition to moving elements */
.fade-group-active,
.fade-group-active {
  transition: opacity 0.5s ease;
}

.fade-group-from,
.fade-group-leave-to {
  opacity: 0;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.fade-group-leave-active {
  position: absolute;
}

//-------

.pop-in-enter-active,
.pop-in-leave-active {
  transition: opacity .5s ease, transform .5s ease;
}

.pop-in-enter,
.pop-in-leave-active, {
  opacity: 0;
  transform: scale(0);
}

//-------

.progress-modal-fade-enter,
.progress-modal-fade-leave-to {
  opacity: 0;
}

.progress-modal-fade-enter-active,
.progress-modal-fade-leave-active {
  transition: opacity .5s ease;
}

.content-modal-fade-enter,
.content-modal-fade-leave-to {
  opacity: 0;
}

.content-modal-fade-enter-active,
.content-modal-fade-leave-active {
  transition: opacity .5s ease;
}

// --- flip word ---

.flip-word-enter-active,
.flip-word-leave-active {
  transition: opacity 0.5s ease;
}

.flip-word-enter-from,
.flip-word-leave-to,
.flip-word-enter,
.flip-word-leave {
  opacity: 0;
}

// --- z index ---

//.index-enter-active,
//.index-leave-active {
//  //z-index: 9999;
//  //transition: z-index .5s;
//}
//
//.index-enter-from,
//.index-leave-to,
//.index-enter,
//.index-leave {
//  opacity: 0;
//}

// --- list ---