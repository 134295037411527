.jungle-badges-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  user-select: none;
  gap: 20px;
  overflow-y: auto;
  height: calc(100% - 36px);
  padding: 10px 0;

  div {
    flex: 0 0 250px;
  }
}

.badges-header,
.shop-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 25px 0;
}

.jungle-aspect-ratio {
  margin-bottom: 83px;
  --ratioHeight: 820;
  --ratioWidth: 1600;
  --widthOverHeight: calc(var(--ratioWidth) / var(--ratioHeight));
  --minHeight: 500px;
  --minWidth: calc(var(--widthOverHeight) * var(--minHeight));

  min-height: var(--minHeight);
  min-width: var(--minWidth);

  aspect-ratio: 1600 / 820;

  max-height: calc(984px - 260px);
  position: relative;
  max-width: calc(1920px - 120px);
}

.is_mobile {
  .jungle-aspect-ratio {
    max-width: calc(100vw - 60px);
    max-height: calc(100vh - 20px);
  }
}

//.jungle-container h3, .jungle-h3 {
//  font-size: 2vmin;
//}
//
//.jungle-container h4, .jungle-h4 {
//  font-size: max(18px, 1.3vw)
//}

html .jungle-container,
html .jungle-container .v-text-field,
.debug-report-modal {

  font-size: calc(15px + 0.6vw) !important;
}

html .jungle-container {
  h2,
  .jungle-h2 {
    font-size: 1.4em;
  }

  h3,
  .jungle-h3 {
    font-size: 1.3em;
  }

  h4,
  .jungle-h4 {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 1017px) {
  html .jungle-container,
  html .jungle-container .v-text-field,
  html.jungle {
    font-size: 21px !important;
  }
}

@media only screen and (min-width: 1452px) {
  html .jungle-container,
  html .jungle-container .v-text-field,
  html.jungle {
    font-size: 24px !important;
  }
}

@media only screen and (max-height: 760px) {
}
