
.v-progress-linear {
  border: 3px solid #3f1d05;
  background-color: rgb(94, 56, 36);
  border-radius: 40px;
  z-index: 95;
  box-shadow: 5px 5px 2px -3px rgb(0 0 0 / 25%);
  user-select: none;
}

.theme--light.v-progress-linear {
  color: white !important;
  font-weight: bold !important;
}

.progress-row {
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.progress-column {
  width: 15%;
  text-align: right;
  height: 35px;

}

.difficulty-stars_container {
  width: fit-content;
  background: white;
  border-radius: 30px;
  position: relative;
  height: 100%;
  transition: scale .2s ease;
  cursor: pointer;
  padding: 0 1%;

  &:hover, &.active {
    scale: 1.05;
  }

  img {
    top: 0;
    height: 80%;
  }
}

.progress-bar {
  width: 50%;
  margin: 0 auto;
}

.difficulty-stars {
  cursor: pointer;
}

.v-progress-linear__content {

}
