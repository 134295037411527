
.avatar {
  aspect-ratio: 3 / 2;
  height: 35%;
  position: absolute;
  display: flex;
  float: right;
  right: 0;
  bottom: 0;
  margin: 25px 0;
  cursor: pointer;
  pointer-events: none;
}

.avatar img {
  pointer-events: all;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 90;
}

.hint-modal {
  z-index: 99;
  background: #fff;
  border-radius: 25px;
  border: 2px solid green;
  width: 500px;
  box-shadow: -5px -5px 4px 2px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 0;
  right: 0;
}

.hint-modal-header {
  display: flex;
  z-index: 999;
  position: absolute;
  color: #4aae9b;
  justify-content: space-between;
}

.hint-modal-body {
  z-index: 999;
  height: 100%;
  padding: 30px;
  position: relative;
}

.hint-modal-body img {
  max-width: 50%;
}

.hint-modal-body p {
  //font-size: 20px !important;
}

.hint-modal-body h3 {
  color: #016d47 !important;
}

.hint-modal-footer .v-btn {
  max-width: 20%;
  margin: 0 auto;
}

.anchor-image {
  position: relative;
  float: right;
}

.btn-hint-close {
  z-index: 10;
  position: absolute;
  width: 62px !important;
  height: 62px !important;
  padding: 5px;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  margin: 0;
  width: 28px;
  font-size: 300% !important;
  cursor: pointer;

  &.previous {
    left: 0;
    transform: translate(-100%, -50%);
  }

  &.next {
    right: 0;
    transform: translate(100%, -50%);
  }
}

.slide-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
