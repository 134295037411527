
.liaan-verticaal {
  background: url('../assets/logboek-liaan-verticaal.png') no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 10px;
  z-index: 0;
  height: 100%;
}
