
.story-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.description {
  margin: -20px 0 20px;
}

.story-list {
  overflow: auto;
  max-height: 330px;
  padding: 0 15px;

  h4 {
    margin: 15px 0;
    font-family: Quicksand, serif !important;
    line-height: 1.5em;
    color: #70706f;
    font-weight: 700;
  }
}

