
.exercise-intro-wrapper {
  width: 100%;
  height: 100%;
}

.intro-buddy-with-sword {
  position: absolute;
  height: 50%;
  transform: scaleX(-1);
  right: 0;
  bottom: 2%;
  z-index: 1;
}

.background-items {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
