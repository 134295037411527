.restricted-input-container, .sentence-input, .unrestricted-input {
  position: relative;
  width: 50%;
  display: flex;
  height: 25%;

  .v-input {
    display: flex;
    align-items: center;
  }

  input {
    font-size: 100%;
    max-height: 20%;
  }
}

.story-input {
  margin: 0 auto;
  width: 40vw;

  .v-textarea textarea {
    line-height: 1.1 !important;
  }
}

.v-text-field--rounded {
  border-radius: 100px !important;
}
.v-text-field {
  padding: 0 !important;
  font-weight: bold;
  font-family: "Quicksand", serif !important;
}