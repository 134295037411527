
.help-overlay__button {
  z-index: 102;
  cursor: pointer;
  transition: all .2s;
  position: absolute;
  right: 50px;
  bottom: 50px;
  pointer-events: all;
  height: 10%;

  &:hover {
    transform: scale(1.1);
  }

  .help-overlay__button__image {
    pointer-events: all;
    height: 100%;
  }
}
