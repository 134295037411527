
.progress_filter_wrapper {
  position: absolute;
  bottom: -340px;
  left: 20px;
  right: 20px;
  overflow: hidden;
  height: 400px;
  transition: .5s;

  &.open {
    bottom: -20px;
  }
}

.progress_filter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  bottom: -20px;
  background: #006c49;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px;
  overflow: hidden;
  height: 320px;
}

.progress_filter_button {
  display: block;
  background: #006c49;
  //background: red;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  align-items: center;
  width: 320px;
  text-align: center;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  pointer-events: all;
  user-select: none;
  cursor: pointer;
  bottom: -20px;
}

.world_filter, .difficulty_filter {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 20px 0;
}

.world_button_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  aspect-ratio: 180/121;
}

.world_button {
  border-radius: 50px;
  height: 100%;
  width: 100%;
}

.difficulty_button {
  border-radius: 50px;
  background: #016D47;
  padding: 10px;
}

.difficulty_button:hover, .world_button:hover {
  cursor: pointer;
}

.active {
  outline: #FFD901 4px solid;
  transform: scale(1.02);
}

h1 {
  margin: -40px 0 -20px 10px;
  padding: 15px 5px 6px 5px;
  width: 65%;
  transform: rotate(-2deg);
  text-align: center;
  background: #FFD900;
  background: url('../../assets/titelbalk.png') no-repeat;
  background-size: cover;
  color: #016D47;
  z-index: 9999;
  image-rendering: auto;
}

.filter-text {
  margin: auto;
  display: block;
  color: white;
}

.world_1_background,
.world_2_background,
.world_3_background,
.world_4_background,
{
  background-size: contain;
  background-repeat: no-repeat;
}
