
.word-list-container {
  height: 100%;
  text-align: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.word-list {
  display: flex;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto !important;
  flex-wrap: wrap;
  min-width: 50%;
  //padding: 20px;

  p {
    font-size: 18px !important;
    color: #016d47 !important;
    margin: 10px !important;

    span {
      margin: 0 3px;
    }
  }
}

.word-list-button-container {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
}
