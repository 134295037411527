
.listening-container {
  height: 100%;
  padding: 0 10px 0 10px;
  text-align: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.animated-word-container {
  display: inline-flex;
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.5s;
}

.letter {
  animation: fade-in 1s ease;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flipped-text {
  margin: 15px 0;
  font-family: "Quicksand", serif !important;
  line-height: 1.5em;
  font-weight: bold;
  font-size: 15px;
  color: white;
}
