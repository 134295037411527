
.block-container {
  display: flex;
  justify-content: center;
}

.sound-group-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sound-group-description {
  font-size: 30px;
  color: #016d47;
}

p {
  margin: 15px 0;
  font-family: "Quicksand", serif !important;
  line-height: 1.5em;
  color: #70706f;
  font-weight: bold;
  font-size: 15px;
}
