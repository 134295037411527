
.typing-input-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .typing-input {
    margin: 0 auto;
  }

  &.shown {
    .typing-input {
      margin: 0 auto;
    }
  }
}

h2 {
  font-size: 40px;
  margin: 50px 0;
  letter-spacing: 5px;
}
