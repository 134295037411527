
.input-hint {
  margin: 3px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
}

.verdubbelaar {
  backface-visibility: visible;
}
