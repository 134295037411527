
.hidden-typing-input-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.restricted-input-container {
  margin: 50px auto;
  height: 15%;
  width: 50%;
}

.input {
  position: relative;
}

.hide-button {
  position: relative;
}

h2 {
  font-size: 60px;
  color: black;
}

