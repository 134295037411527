.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active,
.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform .5s !important;
}

// slide-right

.slide-right-enter,
{
  transform: translateX(100vw) !important;
}

.slide-right-leave-to,
{
  transform: translateX(-100vw) !important;
}

// slide-left

.slide-left-enter,
{
  transform: translateX(-100vw) !important;
}

.slide-left-leave-to,
{
  transform: translateX(100vw) !important;
}

// slide-up

.slide-up-enter,
{
  transform: translateY(-100vh) !important;
}

.slide-up-leave-to,
{
  transform: translateY(100vh) !important;
}

// slide-down

.slide-down-enter,
{
  transform: translateY(100vh) !important;
}

.slide-down-leave-to,
{
  transform: translateY(-100vh) !important;
}


