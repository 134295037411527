
.sentence-input-container {
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.restricted-input-container {
  height: 15%;
  width: 50%;
}

.sentence-input {
  margin: 0 auto;
}

.sentence {
  font-size: 40px;
  color: #000;
}
