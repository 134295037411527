

.v-list-item__title {
  color: #016d47;
  font-weight: bold;
}

.introduction-video {
  width: 100%;
  margin-top: -2px
}
