.leaf {
  background: url("../../assets/leaf-word.png") no-repeat center;
  background-size: contain;
  width: 50%;
  height: 40%;
  margin: 0 auto;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    color: white;
    display: inline;
    transition: all 0.5s;
    letter-spacing: 0.3em;
  }
}

.leaf-backside {
  cursor: pointer;

  svg {
    scale: 3;
  }
}

// replace all descriptions with this class
.exercise-description {
  color: #897f5a;
  user-select: none;
}

.typing-description {
  color: #016d47;
}

.exercise-sentence {
  letter-spacing: 0;
}

.typing-word {
}

.intro-words {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;

  p {
    border-radius: 30px;
    transition: transform .3s;
    color: #016d47!important;
    padding: 3%;
    margin: 0 !important;
    flex-basis: 100px;
    white-space: nowrap;

    &.selected {
      outline: 3px green solid;
      box-shadow: 7px 9px 15px 1px #747474;
      transform: scale(1.04);
    }
  }
}

.intro-selectable-words {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;

  p {
    cursor: pointer;
    border-radius: 30px;
    transition: transform .3s;
    font-size: 1.4vw!important;
    color: #016d47!important;
    padding: 0.5vw;
    margin: 0.5vw !important;
    flex-basis: 100px;
    //
    //&:hover {
    //  box-shadow: 7px 9px 15px 1px #747474;
    //  transform: scale(1.1);
    //}

    &.selected {
      outline: 3px green solid;
      box-shadow: 7px 9px 15px 1px #747474;
      transform: scale(1.04);
    }
  }
}

.liana-left {
  left: 0;
}

.liana-right-1 {
  transform: scaleX(-0.7);
  right: 0;
  height: 100%;
}

.liana-right-2 {
  right: -5%;
  top: -5%;
  z-index: 2;
  transform: scale(1.4);
  height: 100%;
}

.buddy-with-sword {
  // line up empty space at bottom with container
  height: 118%;
}

.test-outro {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.test-outro-message-container {
  position: relative;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: url("../../assets/test-bg.png") no-repeat center;
  background-size: contain;
  width: 50%;
  aspect-ratio: 1008/799;
  padding: 55px;

  .test-outro-message {
    z-index: 1;
    color: #016d47;
    top: 0;
    left: 0;
    line-height: 1.5rem;
    font-weight: bold;
    display: flex;
    text-align: center;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: column;
    justify-content: space-evenly;
    pointer-events: none;
  }

  h2 {
  }

  p, h2 {
    margin: 0;
    font-size: min(200%, 2vw);
  }

  .test-button {
    //position: absolute;
    //top: 60%;
    //left: 50%;
    //transform: translate(-50%, 100%);
    border-radius: 8px;
    padding: 0 16px !important;
    box-shadow: unset;
  }

  //&:before {
  //  pointer-events: none;
  //  content: "";
  //  position: absolute;
  //  aspect-ratio: 1008 / 799;
  //  width: 35vw;
  //  background-size: contain;
  //}
}

.test-outro-kiko-container {
  overflow: hidden;
  bottom: 0;
  right: 0;
  width: 20%;
  position: absolute;
  pointer-events: none;

  img {
    width: 100%;
    margin-top: 70px;
  }
}
