

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes swing-inverse {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes book {
  50% {
    transform: scale(1.1);
  }
}

@keyframes badge {
  50% {
    transform: rotate(5deg);
  }
}

@keyframes naslag {
  50% {
    transform: scale(1.2);
  }
}

@-webkit-keyframes broom {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(3deg);
  }
  0% {
    -webkit-transform: rotate(0deg);
  }
}

@-moz-keyframes broom {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(3deg);
  }
  0% {
    -moz-transform: rotate(0deg);
  }
}

@-o-keyframes broom {
  0% {
    -o-transform: rotate(0deg);
  }
  50% {
    -o-transform: rotate(3deg);
  }
  0% {
    -o-transform: rotate(0deg);
  }
}

@keyframes broom {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(3deg);
  }
  0% {
    transform: rotate(0deg);
  }
}

th {
  color: #016D47 !important;
}

th, td {
  font-size: 16px !important;
}

tr:nth-child(odd) {
  background: #F8F4E0;
}

tr {
  height: 70px;
}

.herhaling-table td:nth-child(1),
.herhaling-table td:nth-child(2) {
  width: 90px;
  color: #026C49;
  font-weight: bold;
  font-size: 18px !important;
}

.herhaling-table td:nth-child(3) {
  font-size: 18px !important;
}

td:last-child {
  text-align: right;
}

select {
  border: 2px solid #53B045;
  color: #53B045;
  border-radius: 5px;
  padding: 5px 25px;
  width: 100%;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

