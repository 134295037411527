
html {
  overflow: hidden !important;
  transition: color 0.3s;

  //::-webkit-scrollbar-thumb:hover {
  //  background-color: rgba(0, 0, 0, 0.58);
  //}
  //
  //::-webkit-scrollbar {
  //  background-color: rgba(0, 0, 0, 0);
  //  width: 10px;
  //  height: 10px;
  //}
  //
  //::-webkit-scrollbar-thumb {
  //  background-color: #146b4b;
  //}
  //
  //::-webkit-scrollbar-corner {
  //  background-color: rgba(0, 0, 0, 0);
  //}


  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #5CB08C;
    width: 20px;
    border-radius: 15px;
    max-height: 65%;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #146B4B;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

body {
  margin: 0;
  background-color: #016d47;
}

.fullscreen {
  // background: url("./assets/achtergrond.png") no-repeat !important;
  // background-size: cover !important;
}

#app {
  position: relative;
  background-color: #016d47;
  // background: url("./assets/logo_groot.png") no-repeat;
  background-size: inherit;
  background-position: center;
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: auto;
  height: 100vh;

  &:before {
    content: "";
    position: fixed;
    /* top: 80px; */
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: background-color .5s;
    z-index: 95;
  }

  &.faded {
    &:before {
      background: rgba(0, 0, 0, 0.4);
      pointer-events: all;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
