
.diamond-hole {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 35%;
  position: absolute;
  z-index: 200;
  aspect-ratio: 8/7;
  $padding: 13%;

  &#diamond-hole-top-left {
    left: $padding;
    top: $padding;
  }

  &#diamond-hole-top-right {
    right: $padding;
    top: $padding;
  }

  &#diamond-hole-bottom-left {
    left: $padding;
    bottom: $padding;
  }

  &#diamond-hole-bottom-right {
    right: $padding;
    bottom: $padding;
  }

  .diamond-in-hole {
    max-width: 100%;
    max-height: 100%;
  }
}
