
.hide-button {
  margin-left: 2%;
  cursor: pointer;
  background-color: #EF8310;
  height: 70%;
  aspect-ratio: 7 / 4;
  max-height: 65px;
  border-radius: 50px;
  border: 2px solid #5C260D;
  position: relative;
  transition: background-color .2s linear;
  display: flex;

  .eye-container {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .eye-icon {
    height: 50%;
    width: auto;
    position: absolute;
    transition: opacity .2s linear;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  &:after {
    background: white;
    border: 2px solid #5C260D;
    height: 80%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: left 0.2s linear, transform 0.2s linear;
    left: 4%;
  }

  &.visible {
    background-color: #4ea440;

    &:after {
      left: 96%;
      transform: translate(-100%, -50%);
    }
  }
}
