

#app:not(.is_mobile) {
  .top-menu {
    max-width: 1440px;
    margin: auto;
  }
}

.top-menu {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 103;

  .logo {
    display: flex;
    margin: 25px;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 98;
  }

  .logo img {
    height: 50px;
    cursor: pointer;
  }
}

.top-menu__buttons {
  z-index: 98;
  position: relative;
  top: 50%;
}

.content-top-menu {
  background: #ffd900;
  //overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  text-align: left;
}

/* badges */

.badges-top-menu {
  display: flex;
  margin-top: 20px;
  max-width: 200px;
  flex-wrap: wrap;
  justify-content: center;
}

.badge-topmenu {
  max-width: 50px;
}

#nav {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0 15px 0 0 !important;
}

#nav svg {
  margin-left: 10px;
}

.menu-item {
  float: right;
  margin: 0 5px;
  box-shadow: 1px 1px 10px rgba(87, 87, 87, 0.4);
  width: 75px;
  border-radius: 50%;
  border-width: 3px;
  border-color: white;
  border-style: solid;
  outline: none;
  background: #186c49;
  cursor: pointer;
  overflow: hidden;
  aspect-ratio: 1 / 1;

  &:hover {
    outline-width: 4px;
  }

  img {
    height: 100%;
    width: 100%;
    padding: 12px;
  }

  .profile {
  }
}

a,
.menu-item {
}


.top-menu {
  &:before {
    content: "";
    position: fixed;
    /* top: 80px; */
    pointer-events: none;
    top: 0;
    left: 0;
    height: 80px;
    width: 100vw;
    transition: background-color .5s, filter 0.3s;
    z-index: 98;
    background: #ffd900;
  }
}

.menu-item:not(.help-overlay__active-item) {
  z-index: 100;
}

//.menu-item:not(.help-overlay__active-item):before {
//  z-index: 100;
//  content: "";
//  position: absolute;
//  /* top: 80px; */
//  pointer-events: none;
//  width: 75px;
//  height: 75px;
//  border-radius: 50%;
//  background: rgba(0, 0, 0, 0);
//  transition: all .5s;
//}

// wtf is this shit

.faded {
  * {
    pointer-events: none;
    transition: background-color 5s;
  }

  .top-menu {
    &:before {
      content: "";
      position: fixed;
      /* top: 80px; */
      pointer-events: none;
      top: 0;
      left: 0;
      height: 80px;
      width: 100vw;
      transition: background-color .5s;
      z-index: 98;
      filter: brightness(50%);
    }
  }

  .menu-item:not(.help-overlay__active-item) {
    z-index: 100;
  }

  .logo {
    filter: brightness(50%);
  }

  //.menu-item:not(.help-overlay__active-item):before {
  //  z-index: 100;
  //  content: "";
  //  position: absolute;
  //  /* top: 80px; */
  //  pointer-events: none;
  //  width: 75px;
  //  height: 75px;
  //  border-radius: 50%;
  //  background: rgba(0, 0, 0, 0.4);
  //}
}

#menu-item__trophy img {
  transform: translateY(5%);
}

.klankenkaart-flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.klankenkaart-image-wrapper {
  aspect-ratio: 2167/1250;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;

  .download-button {
    height: 8% !important;
    font-size: 1.1vw !important;
    padding: 0 16px !important;
    position: absolute;
    bottom: 7%;
    right: 7%;
  }
}

::v-deep .content-modal {
  width: auto;
}

.klankenkaart-modal .content-modal {
  width: fit-content;

}
