
.scoreboard {
  display: flex;
  max-width: 802px;
  background: #006c49;
  margin: -10px auto auto;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 1px 1px 5px #4e4e4e;
  border: 2px solid white;
  height: 363px;
}

.scoreboard-badges-button {
  position: sticky !important;
  bottom: -10px !important;
  margin: 20px 0 0;
  //left: 50%;
}

.scoreboard h3 {
  color: #d2fad8;
  font-weight: bold;
}

.scoreboard-large-txt {
  color: white;
  font-size: 25px;
  font-weight: bold;
}

.scoreboard-punten .scoreboard-large-txt:before {
  background-image: url("../../assets/xp.png");
  background-size: 23px 23px;
  display: inline-block;
  width: 23px;
  height: 23px;
  content: "";
  vertical-align: baseline;
}

.column {
  width: 266px;
  padding: 10px;
  text-align: center;
}

.scoreboard-area {
  border: 1px solid white;
  margin: 20px;
  box-shadow: 1px 1px 5px #4e4e4e;
  height: 140px;
  border-radius: 15px;
  background: #409f78;
  padding: 20px;
}

.scoreboard-badges {
  height: calc(100% - 40px);
  overflow: overlay;
  position: relative;
}

.collected_diamonds__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  height: 90%;
  align-content: center;
  margin: auto;

  img {
    width: 40px;
  }
}
