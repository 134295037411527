
.cut-words-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 auto;
  text-align: center;
  align-items: center;
}

.cut-words-input-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  > div {
    margin: 40px auto;
  }
}

.cut-words-description {
  font-size: 30px;
  color: #016d47;
  margin: 20px auto;
}

.cut-words-word {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2.2em;
  font-weight: bold;
  background: url("../../../assets/boomstam.png") no-repeat 50%;
  background-size: contain;
  height: 24%;
  width: 100%;
  max-width: 67%;
  cursor: url("../../../assets/zaag.png") 10 5, auto !important;
  overflow: hidden;
  position: relative;
}

.cut-words-buttons {
  display: flex;
  width: 750px;
}

.cut-word-letter-transition-group {
  position: relative;
  display: flex;
  justify-content: center;

  &.next-word {
    * {
      transition: none !important;
    }
  }
}

.cut-word-letter {
  color: white;
  position: relative;
  line-height: 100%;
  font-size: 1.5em;
  padding: 0 5px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &:hover {
    &:after {
      background: green;
      content: "";
      position: absolute;
      top: 0;
      right: -2px;
      bottom: 0;
      width: 3px;
    }
  }
}

.cut-word-transition-move,
.cut-word-transition-enter-active,
.cut-word-transition-leave-active,
.cut-word-letter{
  transition: transform .40s ease, opacity .40s ease, max-width .40s ease, padding .40s ease;
}

.cut-word-transition-enter-from,
.cut-word-transition-leave-to {
  opacity: 0;
  max-width: 0 !important;
  padding: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
