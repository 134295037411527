
.library-background {
  background: url("../assets/boekenplank.png") no-repeat;
  background-size: cover;
  height: calc(100vh - 80px);
  width: 100vw;
  padding: 120px 20px 20px 20px;
  position: fixed;
  top: 80px;
  overflow: auto;
}

.library-container {
  background: #F8F4E0;
  border-radius: 5px;
  margin: auto;
  height: calc(100% - 80px);
  width: calc(100vw - 260px);
  position: relative;
  max-height: 1096px;
  min-height: 800px;
  max-width: 1452px;
  min-width: 650px;

  .back-btn {
    top: -95px;
    left: 0;
  }

  .library-content {
    height: 100%;
    overflow: auto;
    padding: 2% 3% 0;
    position: relative;

    .books-list-header {
      display: flex;

      .books-list-header-item:first-child {
        width: 60%;
      }
    }

    .books-list-header .books-list-header-item, .add-book-wrapper .add-book-header {
      color: #016D47;
      margin-bottom: 20px;
      text-align: left;
    }
  }
}

.books-list-container {
  margin: 20px 0;
  border-radius: 0.5rem;

  .books-list {
    background: white;
    border-radius: 0.5rem;

    .books-row {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px 20px;
      gap: 3%;
      position: relative;

      .book-icon-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .book-icon-wrapper, .book-icon-wrapper .book-icon {
        width: 100px;
      }

      .book-info {
        display: flex;
      height: 100%;
      align-items: flex-start;
      flex-direction: column;

        .book-title, .book-review {
          padding: 0;
          margin: 0;
          color: #3aac2c;
          font-size: 1.2rem;
          white-space: nowrap;
          min-width: 10%;
          width: 50%;
        }

        .book-review {
          position: absolute;
          left: 60%;
          text-align: left;
        }
      }

    .book-button-add {
      width: 100%;

      button {
        float: right;
      }
    }

    .book-button-delete {
      width: 100%;

      button {
        float: right;
      }
    }
  }
  }
}

.add-book-wrapper {
  position: sticky;
  left: 0;
  right: 0;
  background: #f8f4e0;
  padding: 20px 0 5px;
  bottom: 0;

  .add-book {
    display: flex;
    width: 100%;
  }

  .add-book-field {
    border-radius: 25px;

    :deep(input), :deep(.v-select__selection) {
      font-size: 20px;
    }

    &:nth-child(1) {
      max-width: 50%;
      min-width: 277px;
    }

    &:nth-child(2) {
      margin: 0 20px;
      max-width: 20%;
      min-width: 232px;
    }

    :deep(.v-select__selection) {
      width: 100%;
      max-width: 100% !important;;
      text-align: center;
    }
  }

  .add-book-button {
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 30%);
  }
}
