

h1 {
  z-index: 9999;
}

.no-notes {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}

.nieuwe-notitie {
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 1000;
}

.v-btn.nieuwe-notitie {
  border: 2px solid white !important;
}

.carousel-3d-container {
  z-index: 999;
  margin: 70px;
  width: calc(100% - 120px);
  height: calc(100% - 140px);
}
::v-deep .carousel-3d-slider {
  height: calc(100% - 15px);
}
.opdracht-slide {
  color: #5E5E5D;
  padding: 40px 30px;
  background: white;
  box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 30%);
  border: none;
  border-radius: 4px;
  height: calc(100% - 15px) !important;
}

.opdracht-slide.current {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
  margin-top: 15px;
}

.opdracht-slide:not(.current) .opdracht-edit {
  background-color: #5E5E5D !important;
}

.opdracht-edit {
  bottom: 30px;
  right: 30px;
  position: absolute;
}

.opdracht-edit.delete {
  right: 85px;
}

.opdracht-datum {
  font-size: 12px;
}

h3 {
  color: #016D47;
}

.profile-row {
  height: 100%;
  box-shadow: none !important;
}

.opdracht-content {
  font-weight: bold;
  margin-top: 25px;
}

.new-note {
  :deep(input), :deep(textarea) {
    font-size: 25px !important;
  }
}
