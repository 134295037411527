
.word-list-container {
  background: white;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  position: relative;

  h3 {
    font-size: 0.7em;
  }

  .world-list-header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 25%);
    z-index: 3;
  }

  :deep(.swiper) {
    width: 100%;
  }

  :deep(.swiper-wrapper) {
    height: 85%;
    position: absolute;
    bottom: 0;
  }
}

.word-page {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin: 0;

  .word-wrapper {
    height: 15%;

    p {
      font-size: 2vh;
      margin: 0;
    }
  }
}
