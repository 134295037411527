
.spell-it__snackbar {
  .v-snack__wrapper {
    background-color: #fcd501 !important;
    color: #016d47 !important;

    .v-btn--text {
      color: #016d47 !important;
    }

    .v-snack__content {
      font-size: 1.2rem !important;
    }
  }
}

.v-snack__action {
  .v-btn {
    margin: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
