

.exercise-container {
  height: 50%;
  position: relative;
}

.exercise-icon {
  height: 100%;
}

.exercise-slide-wrapper {
  width: 100%;
  height: 70%;
  color: #5e5e5d;
  background: transparent;
  position: relative;

  .exercise-name {
    user-select: none;
    background: url("../../assets/titelbalk.png") no-repeat;
    background-size: cover;
    bottom: 10%;
    left: 5%;
    right: 5%;
    position: absolute;
    translate: 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3em;

    h3 {
      font-size: 1em;
      padding: 0 0.5em;
    }
  }
}

.exercise-progress {
  position: absolute;
  width: 25%;
  aspect-ratio: 1 / 1;
  z-index: 999;
  pointer-events: none;
  user-select: none;
}

.exercise-category {
  cursor: pointer;
  transition: transform .2s;
  height: 100%;
}

.exercise-category:not([disabled]):hover {
  transform: scale(1.1);
}

.has-words {
  word-break: word-break;
  width: 100%;
  padding-top: 100px;
  text-align: center;
  font-weight: bold;
  color: #016d47 !important;
}
.exercise-icon {
  margin: 0 auto;
  height: 100%;
}

.exercise-icon-wrapper {
  height: 65%;
  top: 0;
  left: 0;
  position: absolute;
  right: 0;

  &.current-exercise {
    .exercise-icon {
      position: relative;

      &:after {
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%) scale(0.9);
        height: 100%;
        z-index: -1;
        aspect-ratio: 1 / 1;
        position: absolute;
        content: "";

        animation: flashing-exercise ease-in-out infinite 3s;
        pointer-events: none;
      }

      img {
      }
    }
  }
}

.exercise-category {
  cursor: pointer;
  transition: transform .2s;
  height: 100%;
}

.exercise-category:not([disabled]):hover {
  transform: scale(1.1);
}

.exercise-container {
  .swiper {
    height: 100%;
  }
}
