.page-title {
  min-height: 38px;
  height: 55px;
  max-height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 40%;
  transform: rotate(-2deg) scale(0.9);
  background: #ffd900;
  //noinspection CssUnknownTarget
  background: url("../../assets/titelbalk.png");
  background-size: cover;
  position: absolute;
  z-index: 200;
  clear: both;
  left: 0;
  top: -30px;
  user-select: none;

  h1 {
    font-size: 1.3em;
    color: #016d47;
  }
}