
.text-input__wrapper  {
  .v-text-field__details {
    padding: 0 !important;
    margin: 0 !important;
    max-height: 0;
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
  }
  .v-input__slot {
    margin: 0;
    min-height: 5vh !important;
  }
  .v-input  {
    .v-input__append-inner {
      margin: 4px;
    }
    .v-input__control {
      position: relative;
    }
  }
}
