
.jungle-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  .lower-section, .exercise-container {
    width: 100%;
  }

  .lower-section {
    gap: 2%;
    padding: 0;
    display: flex;
    height: 37%;
    justify-content: space-between;

    > * {
      height: 100%;
      width: 32%;
    }

    :deep(.swiper) {
      padding: 0 10%;

      .swiper-button-prev,
      .swiper-button-next {
        width: 10%;
      }

    }

    .character-container {
      width: 35%;
      position: relative;
      user-select: none;

      > * {
        position: absolute;
      }

      .kiko {
        height: 60%;
        left: 15%;
        bottom: 0;
      }

      .compass-boy {
        height: 150%;
        bottom: -15%;
        right: 0;
        z-index: 3;
      }

      :deep(.help-overlay__button) {
        height: 30%;
        bottom: 0;
        right: 0;
      }
    }
  }
}
