//noinspection CssUnknownTarget
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import "transitions/transitions";
@import "animations";
@import "jungle";
@import "world";
@import "exercises";
@import "inputs";
@import "page";

.dots {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.exercise-container, .anchor-image-container {
  .slither-slider {
    background: white;
    height: 100%;
    border-radius: 25px;
  }
}

.word-list-container {
  .slither-slider {
    height: calc(100% - 23px);
  }

  // TODO replace slither slider with something functional :^))))))))
  .slither-slider-barndoor,
  .slither-slider-slides,
  .slither-slider-transition-group:not(:first-child),
  .slither-slider-slide-wrapper,
  .slither-slider-slide {
    height: calc(100% - 10px) !important;
  }
}


#app {
  font-family: 'Quicksand', serif;
  image-rendering: auto;
}

.content {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
}

h1 {
  color: #fff;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: "Quicksand", serif !important;
}

h3 {
  color: #897F5A;
}

span,
p {
  letter-spacing: 0;
  font-family: "Quicksand", serif !important;
}

.introwords p {
  font-size: 28px !important;
}

img.boom {
  border-radius: 50%;
  box-shadow: 1px 1px 10px #000;
  border: 5px solid white;
  position: absolute;
  left: 1325px;
  bottom: 720px;
}

.card {
  background: none;
  width: 700px;
  height: 200px;
  border-radius: 20px;
  margin: 0 auto;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-btn.green {
  background: #3BAD2C !important;
  color: white !important;
  border: 2px solid white;
  box-shadow: 0 1px 10px 0 RGB(0 0 0 / 30%);
  padding: 15px 25px !important;
  font-family: 'Quicksand', serif !important;
  font-size: 18px !important;
  font-weight: bold;
  letter-spacing: 0 !important;
  text-transform: inherit;
}

.v-btn.red {
  background: #EB5D52 !important;
  color: white !important;
  border: 2px solid white;
  box-shadow: 0 1px 10px 0 RGB(0 0 0 / 30%);
  padding: 15px 25px !important;
  font-family: 'Quicksand', serif !important;
  font-size: 18px !important;
  font-weight: bold;
  letter-spacing: 0 !important;
  text-transform: inherit;
}

.v-btn.yellow {
  background: #FFDA00 !important;
  color: #026C49 !important;
  border: 2px solid white;
  box-shadow: 0 1px 10px 0 RGB(0 0 0 / 30%);
  padding: 15px 25px !important;
  font-family: 'Quicksand', serif !important;
  font-size: 18px !important;
  font-weight: bold;
  letter-spacing: 0 !important;
  text-transform: inherit;
}

.v-input input {
  //max-height: unset !important;
  padding: 0 !important;
}

.exercise-title {
  padding: 100px 200px !important;
}

//.avatar {
//  height: 200px;
//}

.avatar img {
  margin-top: 0;
  margin-bottom: -50px;
  float: right;
}

.avatar img {
  height: 300px;
}

/****** OEFENINGEN ******/
h3.oefening-uitleg {
  color: #897F5A;
  margin-bottom: 20px;
  user-select: none;
}

.content-woord {
  margin-top: 30px;
  background-size: contain;
}

.speaker-ul {
  height: 200px;
}

.speaker-ul,
.speaker-ul > li {
  -webkit-transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);
  list-style: none;
}

.speaker-ul li {
  display: flex;
  align-items: center;
  margin: 10px 0;
  color: #186C49;
  font-weight: bold;
}

.speaker-ul li img {
  margin-right: 5px;
}

img, .top-menu, a {
  user-select: none;
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}

.square-button {
  border: 2px solid white !important;
  text-transform: inherit;
  font-weight: bold;
  color: green !important;
  background-color: #FDDA00 !important;
  box-shadow: 0 1px 10px 0 RGB(0 0 0/30%);
  font-size: 18px;
}

.faded-overlay {
  &:after {
    left: 0;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    transition: background-color 0.5s;
    z-index: 97;
  }
}

.faded {
  .faded-overlay {
    &:after {
      background: rgba(0, 0, 0, 0.4);
      pointer-events: all;
    }
  }
}

.exercise__filtered-sentence {
  letter-spacing: 0 !important;
}

.student__coins {
  color: #016d47;
  font-size: 25px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  width: fit-content;
}

.student__coins.coin:before {
  background-image: url("../../assets/xp.png");
  background-size: 23px 23px;
  display: inline-block;
  width: 23px;
  height: 23px;
  content: "";
  vertical-align: baseline;
}

.student__coins.plus {
  font-size: 20px;
  color: #70706f;

  &:before {
    background-image: url("../../assets/xp.png");
    background-size: 20px;
    display: inline-block;
    width: 37px;
    overflow: visible;
    text-align: left;
    content: "+";
    background-position-x: 100%;
    background-position-y: 50%;
  }
}

.flexbox {
  display: flex;

  &.column {
    flex-direction: column;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.justify-content-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.center-absolute {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.center-absolute-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.width-100p {
  width: 100%;
}

.transparent {
  opacity: 0;
}

.overlay__container {
  width: 1440px;
  height: 900px;
  z-index: 200;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  border: 1px red;
  overflow: hidden;
  pointer-events: none;

  .overlay__modal {
    z-index: 200;
    transition: all .5s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
    transform-origin: top;

    * {
      pointer-events: all !important
    }
  }
}

[data-icon="eye"] {
  margin: 0 2px;
}

.align-items-center {
  align-items: center;
}

input {

}

.v-menu__content.rounded {
  border-radius: 25px !important;
}