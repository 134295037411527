
.speaker-icons {
  gap: 2%;
  width: 20%;
  height: 50%;
  position: absolute;
  bottom: -20px;
  display: flex;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
  flex-direction: column-reverse;

  .speaker {
    display: flex;
    gap: 5%;
    height: 14%;
    align-items: center;

    p {
      font-size: 1rem;
    }

    img {
      aspect-ratio: 1/1;
      width: 18%;
      pointer-events: all;
      transition: transform 0.5s;
    }

    &.disabled {
      img {
        opacity: .5;
        pointer-events: none;
      }
    }
  }

  .speaker:not(.disabled) {
    cursor: pointer;
  }

  .active {
    transform: scale(1.3);
  }
}
