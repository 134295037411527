
.full-world-slider {
  height: calc(100vh - 80px);
  width: 100vw;
  position: relative;

  .world-wrapper {
    height: calc(100vh - 80px);
    width: 100vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  img {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    height: calc(100vh - 80px);
  }
}
