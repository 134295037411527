
.assign-color-transition-wrapper {
  height: 100%;
}

.assign-color-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.3em;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.assign-color-container {
  > * {
    transition: all 0.2s;
  }

  .color-container {
    height: 20%;
  }

  .block-container {
    vertical-align: top;
    display: inline-block;
    gap: 1em;
    height: 3.5rem;

    .assign-color-word-container {
      vertical-align: top;
      display: inline-flex;
      transition: all 0.3s;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: fit-content;
      gap: 0.3rem;

      .verdeel-klankgroepen {
        height: 2.5em;
        width: 2.5em;
        border-radius: 25%;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        &.select-letter {
          color: black;
          border: 1px solid darkgrey;

          .letter-class {
            color: black;
          }

          &.filled {
            border: 2px solid #9c9c9c;
            outline: 1px solid #9c9c9c;

            .letter-class {
              color: white;
            }
          }

          &.white {
            .letter-class {
              color: black;
            }
          }
        }
      }

      .letter-class {
        margin: 0;
        padding: 0;
        font-size: 1.5em;
        font-weight: 700;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        color: #898989;
      }
    }

    .color-done-icon {
      scale: 1.5;
    }

    .checkmark-container {
      display: inline-flex;
      height: 100%;
      margin-left: 1em;
      align-items: center;
    }
  }
}

.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
}

.list-complete-leave-active {
  position: absolute;
  left: 0;
  right: 0;
}

//{colors: ["#0c56a8", "#43ab34", "#43ab34", "#0c56a8"], word: "weet"}
//colors: ["#0c56a8", "#43ab34", "#43ab34", "#0c56a8"]
//word: "weet"

.juisteletter {
  color: red !important;
}

.disabledColors {
  background-color: gray !important;
  display: none !important;
}

.restricted-input-container {
  margin: 0 auto;
  height: 15%;
}

.color-btn {
  cursor: pointer;
  margin: 0 5px;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  outline: none;
  border-width: 2px;
  border-color: white;
  border-style: solid;
}

.colors {
  display: flex;
  justify-content: center;
  transition: all 0.3s;
  overflow: visible;
  position: relative;
  max-height: 100%;
  height: 50%;
  max-width: 80%;
  //animation: fadeOut;

  .color-btn:active {
    transform: scale(1.1);
    transition: transform 0.2s;
  }

  &.hidden {
    animation: colorContainerFadeOut 0.2s ease-in-out;
    opacity: 0;
  }
}

@keyframes colorContainerFadeOut {
  0% {
    max-height: 100%;
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}

//.show-checkmark-move, /* apply transition to moving elements */
//.show-checkmark-enter-active,
//.show-checkmark-leave-active {
//  transition-property: all;
//  transition-duration: .5s;
//}
//
//.show-checkmark-enter-from,
//.show-checkmark-leave-to {
//  opacity: 0;
//  width: 0;
//}
//
///* ensure leaving items are taken out of layout flow so that moving
//   animations can be calculated correctly. */
//.show-checkmark-leave-active {
//}

.show-checkmark-enter-active,
.show-checkmark-leave-active {
  transition-property: opacity;
  transition-duration: 0.3s;
}

.show-checkmark-enter,
.show-checkmark-leave-to {
  opacity: 0;
}

.show-checkmark-leave-active {
  position: absolute;
}

//.show-checkmark-enter,
//.show-checkmark-leave-to {
//  opacity: 0;
//}
//
//.show-checkmark-leave-active {
//  position: absolute;
//  left: 0;
//  right: 0;
//}
