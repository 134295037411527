
.world {
  display: block;
  overflow: hidden;
  //height: 484px;
  //width: 720px;
  height: 100%;
  width: 100%;
  transition: all 1s;
  position: relative;

  .back-btn {
    top: 20px;
    left: 20px;
  }
}

.step-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.opdracht:not([data-disabled]) {
  cursor: pointer;
}

.opdracht:not([data-disabled]) img:hover {
  transform: scale(1.1);
}

.opdracht:not([data-disabled]) .active {
  transform: scale(1.1);
  z-index: 3;

  &:after {
    content: "";
    animation: flashing-step linear infinite 2s;
    border-radius: 50px;
    width: 33px;
    height: 33px;
    z-index: 1;
    left: 0;
    top: 0;
    position: absolute;
  }
}

.opdracht:not([data-disabled]) .anchor.active {
  transform: translateX(-50%) scale(1.1);
}

.opdracht {
  width: 4%;
  aspect-ratio: 1/1;

  position: absolute;
  border-radius: 50px;

  img {
    margin: -2px;
    width: 100%;

    &.current-step {
      animation: flashing-step linear infinite 2s;
      border-radius: 50px;

      img {
        width: 100%;
        border-radius: 50px;
      }
    }
  }
}

.end-cave {
  z-index: 5;
  position: absolute;
  left: 48.05%;
  top: 35.43%;
  height: 400px;
  width: 400px;
  transition: all 1s;
  animation: pulse 2s ease-in-out 0s infinite;

  img {
    cursor: pointer;
    height: 400px;
    width: 400px;
    transition: all 1s;
    filter: drop-shadow(13px 0px 22px #000000);
  }
}

.help {
  z-index: 103;
}

.anchor {
  position: absolute;
  left: 50%;
  top: 100%;
  width: 70%;
  transform: translateX(-50%);

  &.disabled {
    pointer-events: none;
    filter: grayscale(0.7);
  }

  img {
    z-index: 2;
    position: relative;
    width: 100%;
  }

  p {
    position: absolute;
    top: 47%;
    left: 50%;
    margin: 0;
    color: white;
    z-index: 2;
    transform: translate(-50%, -50%);
  }
}

.anchor-bubble {
  position: absolute;
  transform-origin: center;
  user-select: none;
  top: calc(50% - 150px);
  //transform: translate(-50%, -50%);
  left: calc(50% - 175px);
  padding: 15px;
  width: 350px;
  height: 300px;
  z-index: 95;
  align-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  .anchor-image-container {
    margin-right: 0;
    width: 300px !important;
    height: 200px !important;
  }

  ::v-deep.anchorimage-slide {
    width: 300px !important;
    height: 200px !important;
  }
}

