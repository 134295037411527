
@keyframes broom {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(3deg);
  }
  0% {
    transform: rotate(0deg);
  }
}

th {
  color: #016D47 !important;
}

th, td {
  font-size: 16px !important;
}

tr:nth-child(odd) {
  background: #F8F4E0;
}

tr {
  height: 70px;
}

.herhaling-table td:nth-child(1),
.herhaling-table td:nth-child(2) {
  width: 90px;
  color: #026C49;
  font-weight: bold;
  font-size: 18px !important;
}

.herhaling-table td:nth-child(3) {
  font-size: 18px !important;
}

td:last-child {
  text-align: right;
}

.repetition-icon {
  width: 50px;
  image-rendering: auto;
}

