

.hide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.input-hint__container {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.v-input {
  border: 2px solid #3bad2c;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.25);
  margin: 0 !important;
  background: white;
  flex-basis: 100%;
}

input {
  max-height: 47px !important;
}

//margin: -8px 0 0;

.typing-input {
  input {
    text-align: center;
  }
}

.wrong-style {
  animation: shake 0.35s ease-in-out 0s 1;
}

.v-input {
  border: 2px solid #3bad2c;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.25);
  margin: 20px 0;
  background: white;
  flex-basis: 100% !important;
}

.typing-input ::v-deep {
  input {
    text-align: center;
  }
}

.restricted-input-container.hideable {
  ::v-deep {
    .v-input__control {
      display: flex;
      align-content: center;
    }
    .v-input__slot {
      padding: 0;
      width: calc(100% - 35%);
    }
  }
}

.verdubbelaar {
  border-radius: 50%;
  padding: 3px;
  right: 15px;
  top: 33px;
  position: absolute;
  z-index: 9999;
}
