
.world_slide {
  position: absolute;
  height: calc(100vh - 80px);
  width: 100vw;
}

.world_slide_wrapper {
  aspect-ratio: 180 / 121;
  max-width: 100vw;
  max-height: calc(100vh - 80px);
  position: relative;
  margin: auto;
  overflow: hidden;
}

.world_slide_background {
  width: 100%;
  height: 100%;
}

.next-world-sign {
  position: absolute;
}

.next-world-sign img {
  transform: rotate(180deg);
}
