
.next-button {
  margin: 0 auto;

  .v-btn {
    height: 7% !important;
    width: 7% !important;
  }

  .v-btn__content {
    height: 60% !important;
    width: 100%;
    position: absolute !important;
  }

  svg ::v-deep {
    height: 100%;
    width: 100%;
  }
}
