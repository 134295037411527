
.speaker:not(.disabled) {
  cursor: pointer;
}

img {
  transition: transform 0.5s;
}

.active {
  transform: scale(1.3);
}
