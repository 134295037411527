
.word-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.word-overview {
  width: 100%;
  display: flex;
  margin: 0 auto !important;
  justify-content: space-around;
}

.word-overview-good, .word-overview-wrong {
  width: 375px;
  padding: 20px 0;
  height: 100%;

  h4 {
    text-align: center;
    font-size: 20px;
    color: #016d47;
    height: 50px;
  }
}

.word-list {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  min-width: 50%;
  margin: 20px 0;
  gap: 10px;

  p {
    font-size: 18px !important;
    color: #016d47 !important;
    font-weight: 700;
    margin: 5px 0;
    text-align: center;
  }
}

.word-list-button-container {
  display: flex;
  align-content: center;
  justify-content: space-around;
}

.side-icon {
  margin: 0 2px;
}
