
.end-cave-avatars {
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    position: absolute;
  }

  .end-cave-lantern {
    width: 35%;
    left: 15%;
    top: 20%;
  }
}

.letter-spawner-zone {
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  user-select: none;

  max-height: 968px;
  height: calc(100vh - 80px);
  aspect-ratio: 180/121;
}

.diamond-drag-zone {
  position: absolute;
  display: flex;
  bottom: 5%;
  right: 0;
  top: 80%;
  left: 0;
  width: 100%;
  user-select: none;
  overflow: hidden;
  justify-content: space-evenly;

  .diamond-drag-zone-diamond {
  }
}

.diamond-drop-zone {
  position: absolute;
  left: 54.5%;
  top: 31.3%;
  opacity: 1;
  aspect-ratio: 1/1;
  width: 23.5%;
}

.content {
  max-height: 968px;
  height: calc(100vh - 80px);
  aspect-ratio: 180/121;
  background: url("../assets/img/end-cave/end-cave.png") no-repeat;
  background-size: cover;
  position: relative;
  margin: 80px auto 0;

  ::v-deep .back-btn {
    top: 3%;
    left: 3%;
  }
}

.end-cave-spider-web {
  width: 10%;
  aspect-ratio: 1 / 4;
  /* height: 10%; */
  position: absolute;
  left: 70px;
  top: 0;

  .end-cave-web {
    display: none;
    width: 170px;
  }

  .end-cave-string {
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: 0;
    width: 4%;
    height: 55%;
  }

  .end-cave-spider {
    transform: scale(-1);

    top: 41%;
    width: 100%;
    z-index: 1;
    position: absolute;

    animation: spider-crawl infinite 4s ease-in-out;
  }
}

@keyframes spider-crawl {
  0% {
    transform: scale(-1) translateY(0);
  }
  50% {
    transform: scale(-1) translateY(-45%);
  }
  100% {
    transform: scale(-1) translateY(0);
  }
}

.end-cave-bat {
  width: 35%;
  left: 30%;
  top: 2%;
  position: absolute;
}

.back-btn:hover {
  + .end-cave-avatars .end-cave-spider-web .end-cave-spider {
    animation-duration: 1s
  }
}

.end-cave-kiko {
  position: absolute;
  top: 7%;
  right: 7%;
  z-index: 900;
  width: 15%;
  display: flex;
}
