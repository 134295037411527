
.story-input-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.has-word {
  margin-top: 0;
}

.visible {
  opacity: 1 !important;
}

.hide-button {
  margin: 40px 0 0;
}

.description {
}

.word {
  font-size: 30px;
  color: black;
  opacity: 0;
  transition: opacity .3s;
}

.chosen-words-container {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 auto;
  transition: all .3s;
  max-height: 35px;

  .chosen-word {
    svg {
      height: 35px;
      width: 35px;
    }
  }

  p {
    padding: 10px;
    margin: 15px !important;
    border-radius: 30px;
  }

  p.found {
    color: #146B4B;
    outline: 3px green solid;
    box-shadow: 7px 9px 15px 1px #747474;
  }
}
