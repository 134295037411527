
.anchor-images-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.description {
  font-size: 30px;
  color: #016d47;
}

.anchor-image-list {
  //display: flex;
  display: block;
  flex-direction: row;
  justify-content: center;
  flex-basis: 250px;

  img {
    width: 230px;
    height: 230px;
    margin: 20px 40px;
    cursor: pointer;
    border-radius: 30px;
    transition: transform .3s;

    &:hover {
      box-shadow: 7px 9px 15px 1px #747474;
      transform: scale(1.1);
    }

    &.selected {
      outline: 3px green solid;
      box-shadow: 7px 9px 15px 1px #747474;
      transform: scale(1.1);
    }
  }
}

.anchor-image-input {
  max-width: 500px;
  margin: 0 auto;
}
