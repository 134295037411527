
.v-input {
  border: 2px solid #3bad2c;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.25);
  margin: 0;
  background: white;
  flex-basis: 100%;
  height: 100%;
}
//
//input {
//  //max-height: 47px !important;
//}
//
//
.wrong-style {
  animation: shake 0.35s ease-in-out 0s 1;
}

.typing-input {
  ::v-deep input {
    text-align: center;
  }
}
