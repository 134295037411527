
.swiper {
  background: white;
  border-radius: 25px;
  padding: 0 5%;
  user-select: none;
  height: 100%;

  .swiper-slide {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 0;
    height: 100%;
    width: 5%;
    margin: 0;
    right: 0;
    color: #70706f !important;
    opacity: 1;
    background: white;
    transition: background .2s;

    &:hover {
      background: rgb(236, 236, 236);
    }

    &.swiper-button-disabled:after {
      opacity: .35;
    }

    &:after {
      font-size: 2vw;
    }
  }
}
