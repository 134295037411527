
.block-input {
  height: 50px;
  max-width: 50px;
  text-align: center;
  font-size: 30px;
  background: white;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1) !important;
  margin: 0 0.1rem;
}

.dash {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
}

.block-dash {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: -10px 5px;
  height: 60px;
}

.block-container {
  display: flex;
  justify-content: center;
}
