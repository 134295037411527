
.test-description-container {
  position: relative;
  width: 50%;
  height: 85%;
  margin: auto;
}

.test-intro-buddy-container {
  bottom: 0;
  right: 0;
  width: 35%;
  height: 60%;
  position: absolute;
  pointer-events: none;

  img {
    height: 100%;
  }
}
