
.speech-bubble-container {
  display: flex;
  aspect-ratio: 1008/799;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: url("../../assets/test-bg.png") no-repeat center;
  background-size: contain;
  padding: 6% 7%;

  p {
    flex: 1;
    margin: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
