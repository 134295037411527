.world_step:not([data-disabled]) {
  cursor: pointer;
}

.world_step:not([data-disabled]) img:hover {
  transform: scale(1.1);
}

.world_step:not([data-disabled]) .active {
  transform: scale(1.1);
  z-index: 3;

  &:after {
    content: "";
    animation: flashing-step linear infinite 2s;
    border-radius: 50px;
    width: 33px;
    height: 33px;
    z-index: 1;
    left: 0;
    top: 0;
    position: absolute;
  }
}

.world_step:not([data-disabled]) .anchor.active {
  transform: translateX(-50%) scale(1.1);
}

.world_step {
  position: absolute;
  width: 4%;
  aspect-ratio: 1 / 1;
  border-radius: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;

    &.world_step--current {
      animation: flashing-step linear infinite 2s;
      border-radius: 50px;

      img {
        border-radius: 50px;
      }
    }
  }
}

.world_1_background {
  background: url("../../assets/first-world-bg.png");
  background-size: cover;
}

.world_2_background {
  background: url("../../assets/second-world-bg.png");
  background-size: cover;
}

.world_3_background {
  background: url("../../assets/third-world-bg.png");
  background-size: cover;
}

.world_4_background {
  background: url("../../assets/fourth-world-bg.png");
  background-size: cover;
}