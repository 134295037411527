
.login {
  background: url("../assets/achtergrond.png");
  background-size: cover;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 80px;
  bottom: 0;
  overflow: overlay;
  padding: 0 50px 50px;

  .logo {
    padding: 20px 10px;
  }

  .logo img {
    height: 100%;
  }

  &:after {
    content: "";
    background: black;
    min-height: 600px;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    pointer-events: none;
  }
}

.login .v-input {
  margin: 25px 0;
}

.login-logo {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

h1 {
  color: #fff;
  width: inherit;
  transform: inherit;
  background: none;
  margin: 0;
  position: relative;
  z-index: 1;
}

.v-input ::v-deep label {
  text-align: center;
  left: 50% !important;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 1.2rem !important;
  color: #016d47 !important;
  height: 25px;
}

.v-input ::v-deep input {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem !important;
  color: #016d47 !important;
  margin: 0 20px;
}

.shaped-div.logo img {
  max-width: 500px;
  image-rendering: -webkit-optimize-contrast;
}

form {
  width: 35%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.v-btn {
  width: 100%;
  font-family: "Quicksand";
  font-weight: bold;
  font-size: 1.2rem !important;
  color: #016d47 !important;
  text-transform: inherit;
}

a.lost-password {
  color: white;
}

.password-eyecon {
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}
