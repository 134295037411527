
.loading-message-container {
  position: relative;
  height: 100%;
  width: 100%;

  .loading-message {
    position: absolute;
    top: 50%;
    left: 50%;  transform: translate(-50%, -50%);
    font-size: 30px;
    color: #016d47;
  }
}
