
.bubble {
  border-radius: 20px;
  padding: 30px;
  background: white;
  border: 2px solid green;
  max-width: 400px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;

  .bubble_header {
    margin-bottom: 25px;
    color: #016d47;
    display: flex;
    justify-content: space-between;
  }

  .close-button {
    width: 30px;
    height: 30px;
    transition: transform .5s;
    cursor: pointer;
    margin-left: 10px;

    &:hover {
      transform: scale(1.1);
    }
  }

  .bubble_body {
    white-space: pre-wrap;
    color: #2c3e50;
    font-weight: bold;
  }

  .bubble_footer {
    margin-top: 25px;
    color: #016d47;
  }
}
