
::v-deep {
  .scoreboard {
    border-radius: 30px;
    padding: 30px;
    height: 100%;
  }

  .scoreboard-area:not(.scoreboard-badges) {
    height: 43%;
  }
}
