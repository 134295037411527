
.coinsbackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.coinspopup {
  overflow-x: auto;
  z-index: 99;
  background: #fff;
  border-radius: 25px;
  border: 2px solid green;
  width: 450px;
  height: 500px;
  right: 0;
  bottom: 0;
  padding: 50px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
}

.coinspopup img {
  max-height: 200px;
  margin: 20px 0 20px 0;
}

h3.coinsPoints:before {
  background-image: url("../assets/xp.png");
  background-size: 23px 23px;
  display: inline-block;
  width: 23px;
  height: 23px;
  content: "";
  vertical-align: baseline;
}

.coinsBtn {
  position: absolute;
  bottom: 50px;
  margin: 0 auto;
}

.btn-coins-close {
  width: 50px;
  border: 50px;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
  z-index: 999;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 !important;
}
