
.test-input-container {
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.test-description {
  font-size: 30px;
  color: #016d47;
}

.test-input {
  height: 20%;
}
