
.debug-button-wrapper {
  border-radius: 1em;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  padding: 0 15px;
  left: 15px;
  gap: 10px;
  background: #ffd900;
  z-index: 50;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;

  &#debug-button-app {
    bottom: 20px;
    position: fixed;
  }

  &#debug-button-jungle {
    position: absolute;
    top: calc(100% + 20px)
  }

  &:hover {
    background: #e7c300;
  }

  *,
  p {
    user-select: none;
    pointer-events: none;
    color: #186c49;
    margin: 0;
    padding: 0;
    font-size: 15px;
  }
}
