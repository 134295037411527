
.download-container {
  height: 400px;
  width: 600px;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.button-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.compass-boy {
  left: -80px;
  bottom: -50px;
  position: absolute;
  width: 25vw;

  img {
    width: 100%;
  }
}

.kiko {
  width: 15vw;
  right: 0;
  bottom: 0;
  position: absolute;

  img {
    width: 100%;
  }
}
