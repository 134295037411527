
.progress-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.progress-modal {
  display: flex;
  flex-direction: column;
  z-index: 99;
  background: #F8F4E0;
  border-radius: 25px;
  outline: 4px solid #006c49;
  width: 80vw;
  height: 70vh;
  position: relative;
}

h1 {
  margin: -40px 0 -20px 10px;
  padding: 15px 5px 6px 5px;
  width: 65%;
  transform: rotate(-2deg);
  text-align: center;
  background: #FFD900;
  background: url('../../assets/titelbalk.png') no-repeat;
  background-size: cover;
  color: #016D47;
  z-index: 9999;
  image-rendering: auto;
}

.progress-modal-header,
.progress-modal-footer {
  display: flex;
  z-index: 999;
}

.progress-modal-header {
  position: absolute;
  width: 600px;
  color: #4AAE9B;
  justify-content: space-between;
}

.progress-modal-body {
  z-index: 999;
  background: #F8F4E0;
  height: 100%;
  overflow: auto;
  display: flex;
}

.progress-modal-body-wrapper {
  overflow: hidden;
  position: relative;
  padding: 50px 60px;
  height: 100%;
}

.progress-modal-footer {
  width: 600px;
  margin: 0 auto;
  flex-direction: column;
}

.btn-progress-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 62px;
  border: 62px;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
  z-index: 999;
}

.green {
  color: white;
  background: #4AAE9B;
  border-radius: 2px;
  transition: transform .5s ease-out;

  &.active {
    transform: scale(1.1);
  }
}

.insufficient-filter-disclaimer {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
}

.v-data-table {
  width: 100%;
}

::v-deep {
  .v-data-table__wrapper {
    background: white;
  }
}

.practiced-words {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 1s;
  padding: 20px 0 0 20px;
  flex-wrap: nowrap;

  ul {
    list-style: none;
    transition: all 1s;
    width: fit-content;

    li {
    }

    img {
      margin-bottom: -12px;
      display: inline-block;
    }

    p {
      display: inline-block;
      line-height: 40px !important;
      margin: 0 0 0 10px;
    }
  }

  &.open {
    width: 400px;
  }

  width: 0;
}

.no-words {
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .long-button {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .short-button {
    display: none;
  }
}

tr::after {
  content: "";
}

::v-deep .v-data-table__empty-wrapper {
  display: none;
}

::v-deep .v-data-table__expanded__content {
  td {
    background: #f7f3df;
  }
}
