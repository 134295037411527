
.bug-report-form {
  width: 100%;

  .form-submit-button-wrapper {
    padding: 0 0 10px;
  }
}

.debug-report-modal {
  h2 {
    font-size: 30px;
    line-height: 30px;
  }
  p, textarea {
    font-size: 24px;
    line-height: 24px;
  }
}

:deep(.content-modal) {
  --height: 930px;
  --width: 700px;
  width: var(--width);
  min-width: var(--width);
  max-width: var(--width);

  height: var(--height);
  //min-height: var(--height);
  max-height: 80%;

  padding: 24px 48px;

  .content-modal-body-wrapper {
    padding: 50px 0 0;
  }

  .content-modal-body {
    padding: 0 5px;
  }
}

:deep(.v-input) {
  width: 100%;

  &.v-textarea {
    textarea {
      line-height: 27px;
    }

    max-height: 450px;
  }

  .v-select__selections {
    height: 100%;
  }

  .v-select__selection {
    max-width: 100%;
  }

  .v-text-field .v-input__slot, &.v-text-field--rounded {
    border-radius: 25px !important;
  }

  .v-input__append-inner {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 !important;
  }
}
