
.v-input {
  border: 2px solid #3bad2c;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.25);
  background: white;
  flex-basis: 100%;
  margin: 50px 0;
}

.text-area-container {
  position: relative;
}

.hide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

input {
  max-height: 47px !important;
}

.v-text-field--rounded {
  border-radius: 50px !important;
}

.v-text-field {
  padding: 15px !important;
  font-size: 32px !important;
  font-family: "Quicksand", serif !important;
}

.has-visible-slider {
  padding-left: 120px !important;
}
.v-input {
  border: 2px solid #3bad2c;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.25);
  margin: 0;
  background: white;
  flex-basis: 100% !important;
}
