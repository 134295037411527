
.diamond-wrapper {
  position: relative;
  user-select: none;
  height: 100%;
}

.filled {
  //margin-left: -10px;
  //margin-top: -7px;

  .diamond {
    width: 100%;
    position: absolute;
    cursor: default;
  }
}

.diamond {
  cursor: pointer;
  width: 10vw;
}

.diamond-drag-zone:not(.being-dragged) {
  .diamond-wrapper {
    aspect-ratio: 1/1;
  }

  .diamond {
    cursor: pointer;
    width: 100%;
  }
}

.being-dragged {
  position: fixed;
  transform: translate(-50%, -50%) scale(1.3);
  z-index: 100000;

  .diamond {
    width: 10vw;
  }
}

.jiggling {
  animation: jiggle 0.5s infinite;
  transform: translate(-50%, -50%) scale(1.3) rotate(-5deg);
}

@keyframes jiggle {
  0% {
    transform: translate(-50%, -50%) scale(1.3) rotate(-5deg);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.3) rotate(5deg);
  }
}
