
.story-input-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.has-word {
  margin-top: 0;
}

.visible {
  opacity: 1 !important;
}

.hide-button {
  margin: 40px 0 0;
}

.description {
}

.word {
  font-size: 30px;
  color: black;
  opacity: 0;
  transition: opacity .3s;
}
