
.content-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.content-modal {
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  z-index: 103;
  background: #f8f4e0;
  border-radius: 25px;
  border: 4px solid #006c49;
  width: 80vw;
  height: 70vh;
  position: relative;
}

.content-modal-header,
.content-modal-footer {
  display: flex;
  z-index: 999;
}

.content-modal-header {
  position: absolute;
  color: #186c49;
  justify-content: space-between;
}

.content-modal-body {
  z-index: 999;
  background: #f8f4e0;
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
}

.content-modal-body-wrapper {
  overflow: hidden;
  position: relative;
  padding: 50px 60px;
  height: 100%;
}

.content-modal-footer {
  width: 600px;
  margin: 0 auto;
  flex-direction: column;
}

.btn-progress-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 62px;
  border: 62px;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
  z-index: 999;
}
