
.scoreboard-trophy {
  width: 25px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.scoreboard-btn {
  display: block;
  background: #006c49;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: white;
  font-weight: bold;
  padding: 20px 20px 10px 20px;
  margin: -2px auto;
  box-shadow: -2px 3px 3px #8e8e8e, 2px 3px 3px rgba(0, 0, 0, 0.5);
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  align-items: center;
}

.scoreboard__dropdown {
  display: block;
  position: absolute;
  margin: auto;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -363px);
  transition: transform 1s !important;
  pointer-events: none;

  &.active {
    transform: translate(-50%, 0);
  }

  * {
    pointer-events: all;
  }
}

.faded {
  .scoreboard__dropdown:not(.help-overlay__active-item) {
    filter: brightness(50%);
  }
}
