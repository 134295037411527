.wrong-input {
  animation: shake .3s ease-in-out 0s 1;
}

@keyframes shake {
  0% {
    transform: translateX(0);
    outline: 2px solid red;
  }
  20% {
    transform: translateX(0.5rem);
    outline: 2px solid red;
  }
  40% {
    transform: translateX(-0.5rem);
    outline: 2px solid red;
  }
  60% {
    transform: translateX(0.5rem);
    outline: 2px solid red;
  }
  80% {
    transform: translateX(-0.5rem);
    outline: 2px solid red;
  }
  100% {
    transform: translateX(0);
    outline: 2px solid red;
  }
}

//------

@keyframes fade-shrink-vertically {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// --- flashing step

@keyframes flashing-step {
  0% {
    box-shadow: 0 0 20px 15px #ffd900;
  }
  50% {
    box-shadow: 0 0 20px 5px #ffd900;
  }
  100% {
    box-shadow: 0 0 20px 15px #ffd900;
  }
}

// --- flashing exercise

@keyframes flashing-exercise {
  0% {
    box-shadow: 0 0 20px 15px #ffd900;
  }
  50% {
    box-shadow: 0 0 20px 0px #ffd900;
  }
  100% {
    box-shadow: 0 0 20px 15px #ffd900;
  }
}

// --- pulse

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}