
.exercise-intro {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  position: relative;
}

.exercise-word-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;

  h4 {
    color: #70706f;
    margin-bottom: 2%;
  }
}

.exercise-description {
  top: 20%;
  padding: 0 200px;
  text-align: center;
}

h3 {
  font-size: 30px;
  color: #016d47;
}
